// @ts-strict-ignore
import { FC, useState } from 'react'
import { getKontentImage } from '@lib/kontent'
import { HomepageHeroItem } from '@lib/kontent/models/HomepageHeroItem'
import { theme } from '@gassan-ui'
import { cn } from '@lib/cn'
import { m } from 'framer-motion'
import { RolexHeroSliderItem } from '@lib/kontent/models/RolexHeroSliderItem'

type Props = (HomepageHeroItem | RolexHeroSliderItem) & {
  slideToPreload: HomepageHeroItem | RolexHeroSliderItem
}

const getSources = (sources: string[]) =>
  sources
    .map((src: string, index: number) => (
      <source
        key={index}
        media={index !== 0 ? `(min-width: ${theme.breakpoints[index]})` : undefined}
        srcSet={src}
      />
    ))
    .reverse()

const HomepageHeroSliderImage: FC<Props> = ({
  desktop_image,
  mobile_image,
  title,
  slideToPreload,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const handleOnLoad = () => setIsLoaded(true)
  const placeholderSrc = getKontentImage(mobile_image.value[0].url, { width: 50 })

  const sources = [
    getKontentImage(mobile_image.value[0].url, { width: 768 }),
    getKontentImage(desktop_image.value[0].url, { width: 1024 }),
    getKontentImage(desktop_image.value[0].url, { width: 1440 }),
    getKontentImage(desktop_image.value[0].url, { width: 1920 }),
    getKontentImage(desktop_image.value[0].url, { width: 2560 }),
  ]

  const preloadSources = [
    getKontentImage(slideToPreload.mobile_image.value[0].url, { width: 768 }),
    getKontentImage(slideToPreload.desktop_image.value[0].url, { width: 1024 }),
    getKontentImage(slideToPreload.desktop_image.value[0].url, { width: 1440 }),
    getKontentImage(slideToPreload.desktop_image.value[0].url, { width: 1920 }),
    getKontentImage(slideToPreload.desktop_image.value[0].url, { width: 2560 }),
  ]

  return (
    <m.div
      className={cn('absolute left-0 top-0 h-full w-full')}
      transition={{ duration: 0.5, ease: 'linear' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={title.value}
    >
      <img
        className="absolute left-0 top-0 z-[1] h-full w-full object-cover blur-lg"
        alt={mobile_image.value[0].description}
        src={placeholderSrc}
        style={{ opacity: isLoaded ? 0 : 1 }}
      />
      <picture>
        {getSources(preloadSources)}
        <img
          src={preloadSources[0]}
          alt={slideToPreload.desktop_image.value[0].description}
          className="hidden"
          loading="eager"
        />
      </picture>
      <picture className="absolute left-0 top-0 z-[2] h-full w-full">
        {getSources(sources)}
        <img
          suppressHydrationWarning
          loading="eager"
          onLoad={handleOnLoad}
          src={sources[0]}
          alt={desktop_image.value[0]?.description}
          className="flex h-full w-full object-cover"
        />
      </picture>
    </m.div>
  )
}

export default HomepageHeroSliderImage
