// @ts-strict-ignore
import Cookies from 'js-cookie'
import { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { buildLanguageAlternateNavigation } from '@lib/build-language-alternates'
import { emarsysHomepageVisit } from '@lib/emarsys-tracking/emarsys-homepage-visit'
import { getServerSideTranslations } from '@lib/get-server-side-translations'
import { getDataCodenameValues } from '@lib/getDataCodenameValues'
import { useAuth } from '@lib/hooks/use-auth'
import { useCart } from '@lib/hooks/useCart'
import { SingleItemResponse, getPageByCodename } from '@lib/kontent'
import { Homepage2022 } from '@lib/kontent/models/Homepage2022'
import { useEmarsysStore } from '@lib/use-emarsys-store'
import LanguageNudge from '@components/Nudges/LanguageNudge'
import { FloatingServiceMenu, Footer, Header, Page, PageTransition } from '@components/Page'
import { pickSeoProps } from '@components/Page/Page'
import ContentBlocks from '@components/_content-page/ContentBlocks'
import Hero from '@components/_home/Hero'
import { HomepageHeroItem } from '@lib/kontent/models/HomepageHeroItem'

type Props = SingleItemResponse<Homepage2022> & {}

const Homepage: NextPage<Props> = ({ item, modular_content }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const heroItems = item.elements.hero_item.value.map(
    (key) => modular_content[key].elements,
  ) as HomepageHeroItem[]

  const keys = getDataCodenameValues(item.elements.homepage___content_blocks__blocks.value)
  const contentBlocks = keys.map((key) => ({
    type: modular_content[key].system.type,
    elements: modular_content[key].elements,
  }))

  const cart = useCart()
  const { scriptIsLoaded } = useEmarsysStore()
  const { me } = useAuth()
  const customerId = me && me.dafUser && me.dafUser.klant_nr

  useEffect(() => {
    Cookies.set('NEXT_LOCALE', language)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (cart && scriptIsLoaded) {
      emarsysHomepageVisit({
        customerId,
        cart,
      })
    }
  }, [scriptIsLoaded, cart]) // eslint-disable-line
  const router = useRouter()
  return (
    <Page
      canonical={language === 'nl' ? 'https://www.gassan.com' : 'https://www.gassan.com/en'}
      {...pickSeoProps(item.elements)}
      languageAlternates={buildLanguageAlternateNavigation(router.locales, null, '/')}
    >
      <LanguageNudge />
      <FloatingServiceMenu variant="appear-on-scroll" />
      <Header initialVariant="absolute" />
      <PageTransition>
        <Hero items={heroItems} />
        <ContentBlocks contentBlocks={contentBlocks} modularContent={modular_content} />
        <Footer />
      </PageTransition>
    </Page>
  )
}

export const getStaticProps: GetStaticProps = async ({ preview, locale }) => {
  try {
    const content = await getPageByCodename<Homepage2022>({
      codename: 'homepage_2022',
      lang: locale,
      preview,
    })

    return {
      props: {
        ...(await getServerSideTranslations(locale)),
        ...content,
      },
    }
  } catch (error) {
    throw error
  }
}

export default Homepage
