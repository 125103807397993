import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { hasCookieConsent } from '@lib/cookies'
import { useLocalization } from '@components/Localization'
import { useNotifications } from '@components/NotificationsProvider'

const LanguageNudge = () => {
  const notifications = useNotifications()
  const { setLang } = useLocalization()
  const { route } = useRouter()

  const setPreferredLanguage = () => {
    setLang('en')
  }

  useEffect(() => {
    return () => notifications.removeNotification()
  }, [route]) // eslint-disable-line

  const timer = useRef<number>()

  useEffect(() => {
    if (window && route === '/') {
      const hasShownLangNudge = !!Cookies.get('language_nudge_shown')
      const cookieConsent = hasCookieConsent()
      if (!hasShownLangNudge && cookieConsent && window.innerWidth <= 640) {
        timer.current = window.setTimeout(() => {
          notifications.addNotification({
            variant: 'language-switch',
            onClickAction: () => setPreferredLanguage(),
          })
          Cookies.set('language_nudge_shown', 'true', { expires: 365 })
        }, 1000)
      }
    }
    return () => {
      clearTimeout(timer.current)
    }
  }, []) // eslint-disable-line

  return null
}

export default LanguageNudge
